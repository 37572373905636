<template>
	<div class="header_mto">
		<img class="headerimg" src="@/assets/images/img_6(4).jpg" />
		<img class="headerimg" src="@/assets/images/img_6(5).jpg" />
		<div class="headerfont">
			<p>Made to</p>
			<p>Order</p>
		</div>
	</div>
	<div id="endtext">
		<p>Coming Soon!</p>
	</div>
</template>
